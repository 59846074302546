<template>
  <div class="admin-signin-page">
    <!-- Le logo et le titre -->
    <md-empty-state md-icon="build" md-label="Administration MEEP">
      <!-- Le champ email -->
      <md-field :class="{ 'md-invalid': errors.has('email') }">
        <label>E-mail</label>
        <md-input
          v-model="form.email"
          v-validate="'required|email'"
          name="email"
          type="email"
          @keyup.enter.native="login"
        ></md-input>
        <span class="md-error" v-show="errors.has('email')">{{
          errors.first("email")
        }}</span>
      </md-field>

      <!-- Le champ mot de passe -->
      <md-field :class="{ 'md-invalid': errors.has('password') }">
        <label>Mot de passe</label>
        <md-input
          v-model="form.password"
          v-validate="'required'"
          name="password"
          type="password"
          @keyup.enter.native="login"
        ></md-input>
        <span class="md-error" v-show="errors.has('password')">{{
          errors.first("password")
        }}</span>
      </md-field>

      <!-- Le bouton de connexion -->
      <md-button
        class="md-primary md-raised signin-button"
        @click.native="login"
        >Connexion</md-button
      >
      <!-- Mot de passe oublié -->
      <router-link to="/forgot-password-admin"
        >Mot de passe oublié ?</router-link
      >
    </md-empty-state>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDebug: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("SIGNIN_ADMIN", this.form)
            .then(() => {
              this.$router.push("/admin");
            })
            .catch((err) => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-signin-page {
  height: 100vh;
  display: flex;
  justify-content: space-around;

  .signin-button {
    width: 90%;
  }

  a {
    margin-top: 16px;
  }
}
</style>
